import { GoogleAnalyticsService } from './../../google-analytics.service';
import { endpoints } from './../../config/endpoints';
import { ISessionTime, ITiempo } from './tiempos.interface';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IServer } from './server.interface';
import { of } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-timing-table',
  templateUrl: './timing-table.component.html',
  styleUrls: ['./timing-table.component.scss'],
  providers: [
    DecimalPipe
  ]
})
export class TimingTableComponent implements OnInit {

  serverTimes: ISessionTime;
  tiempos: ITiempo[] = [];
  private tiemposCopy: ITiempo[] = [];

  loading: boolean = true;
  loadingTimetable: boolean = false;
  noTimes: boolean = false;

  errorNoInitData: boolean = false;

  servidores: IServer[] = [];
  circuitos: string[] = [];
  categorias: string[] = [];

  // desktopColumns: string[] = ['pos', 'fullName', 'carModelDes', 'bestLaptimeFmt', 'gapToFirst'];
  // desktopColumns: string[] = ['pos', 'fullName', 'carModelDes', 'validLaps', 'totalLaps', 'bestLaptimeFmt', 'gapToFirstFmt', 'isWetTime'];
  desktopColumns: string[] = ['pos', 'fullName', 'validLaps', 'totalLaps', 'bestLaptimeFmt', 'split1', 'split2', 'split3', 'isWetTime'];
  mobileColumns: string[] = ['pos', 'fullName', 'validLaps', 'bestLaptimeFmt'];

  displayedColumns: string[] = this.desktopColumns;
  columnsToDisplay: string[] = this.displayedColumns.slice();

  isMobile: boolean = false;

  currentCat: string;
  currentTimeTable: 'all' | 'wet' | 'dry';

  serverChanging: boolean = false;
  sessionCars: string[] = [];
  currentFilterCar = null;

  /** Controla si ha habido algún problema de datos con el servidor */
  serverDataError: boolean = false;

  form: FormGroup = this.fb.group({
    server: [],
    circuito: [''],
    categoria: ['']
  });


  get CurrenServer(): string {
    return (this.form.get('server').value as IServer).server;
  }

  get CurrenTrack(): string {
    return this.form.get('circuito').value;
  }

  get CurrenCat(): string {
    return this.form.get('categoria').value;
  }

  get TimeKeys(): string[] {
    return Object.getOwnPropertyNames(this.serverTimes);
  }

  get ServerTrackCat(): string {
    return this.servidores.find(y => y.server === this.CurrenServer).desc  + '/' + this.CurrenTrack + '/' + this.CurrenCat;
  }

  constructor(private http: HttpClient,
              private breakpointObserver: BreakpointObserver,
              public fb: FormBuilder,
              private number: DecimalPipe,
              private ga: GoogleAnalyticsService
              ) {

    this.getData();

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
        if (result.matches) {
          this.displayedColumns = this.mobileColumns;
        }
        else {
          this.displayedColumns = this.desktopColumns;
        }
        this.columnsToDisplay = this.displayedColumns.slice();

      });


    this.form.get('server').valueChanges
      .pipe(
        switchMap(x => {
          this.serverChanging = true;
          return this.http.get<string[]>(endpoints.categories);
        }),
        switchMap(cs => {
          this.categorias = cs;
          if (cs && cs.length) {
            this.form.get('categoria').setValue(this.categorias[0]);
          }
          return this.http.get<string[]>(endpoints.tracks(this.CurrenServer));
        })
      )
      .subscribe(x => {
        this.circuitos = x;
        if (x && x.length) {
          this.form.get('circuito').setValue(this.circuitos[0]);
        }
        if (!this.servidores.length || !this.categorias.length || !this.circuitos.length) {
          this.errorNoInitData = true;
        }
        else {
          this.loading = false;
          this.obtenerTiempos();
        }
        this.serverChanging = false;

      }).add(() => this.loading = false);


    this.form.get('circuito').valueChanges
      .subscribe(x => {
        if (!this.serverChanging) {
          this.obtenerTiempos();
        }
      });

    this.form.get('categoria').valueChanges
      .subscribe(x => {
        if (!this.serverChanging) {
          this.obtenerTiempos();
        }
      });
  }

  private getData(): void {
    this.errorNoInitData = false;
    this.serverDataError = false;

    this.http.get<IServer[]>(endpoints.servers)
      .subscribe(x => {
        this.servidores = x;
        if (x && x.length) {
          this.form.get('server').setValue(x[0]);
        }
      });
  }

  private obtenerTiempos(): void {

    if (this.CurrenCat && this.CurrenTrack) {

      this.currentTimeTable = 'all';
      this.serverTimes = null;
      this.loadingTimetable = true;
      this.serverDataError = false;
      this.http.get<ISessionTime>(endpoints.timetable(this.CurrenServer, this.CurrenTrack, this.CurrenCat))
        .pipe(
          map(x => {
            // x.forEach((element, index) => {
            //   element.pos = (index + 1).toString();
            // });
            x.all.forEach(el => el.fullName = el.firstName + ' ' + el.lastName);
            x.wet.forEach(el => el.fullName = el.firstName + ' ' + el.lastName);
            x.dry.forEach(el => el.fullName = el.firstName + ' ' + el.lastName);
            return x;
          }),
          map(x => {
            x.all.forEach((el, index) => el.pos = index + 1);
            x.wet.forEach((el, index) => el.pos = index + 1);
            x.dry.forEach((el, index) => el.pos = index + 1);
            return x;
          }),
          catchError(err => {
            console.log(err);
            this.serverDataError = true;
            return of(null);
          })
        )
        .subscribe(x => {

          if (x !== null) {
            this.ga.eventEmitter(window.location.origin,
                                  { event_category: 'Server Info',
                                  event_label:  this.ServerTrackCat
                                });
            this.serverTimes = x;
            this.tiempos = [];
            if (!this.serverTimes.all.length) {
              this.noTimes = true;
            }
            else {
              this.selectTimeTable();
            }
          }
          this.loadingTimetable = false;
        },
          err => this.loadingTimetable = false);
    }

  }

  selectTimeTable(): void {
    this.tiemposCopy = this.tiempos = this.serverTimes[this.currentTimeTable];
    this.sessionCars = this.tiempos.map(x => x.carModelDes).filter((el, index, ar) => ar.indexOf(el) === index).sort((a,b) => { if (a<b) return -1; if (a>b) return 1; return 0;});
    this.noTimes = !this.tiempos.length;
  }

  filtrarTiemposPorCoche(): void {
      if (!this.currentFilterCar) {
        this.tiempos = [...this.tiemposCopy];
      }
      else {
        this.tiempos = [...this.tiemposCopy].filter(x => x.carModelDes === this.currentFilterCar);
      }

      this.ga.eventEmitter(window.location.origin,
        { event_category: 'Filter Tabletime by car',
        event_label:  this.ServerTrackCat,
        value: this.currentFilterCar
      });
  }

  rowClass(gapToFirst: number): string {
    let clase = 'tooFar';
    if (gapToFirst < 5) {
      clase = 'gap4-5';
    }
    if (gapToFirst < 4) {
      clase = 'gap3-4';
    }
    if (gapToFirst < 3) {
      clase = 'gap2-3';
    }
    if (gapToFirst < 2) {
      clase = 'gap1-2';
    }
    if (gapToFirst < 1) {
      clase = 'gap0-1';
    }
    if (gapToFirst === 0) {
      clase = 'first';
    }
    if (gapToFirst === null) {
      clase = '';
    }
    return clase;
  }

  cargarCat(): void {
    console.log(this.currentCat);
  }

  getSplit(element, pos): string {
    return element.bestLap && this.number.transform(element.bestLap.splits[(pos - 1)]).replace(',', ':') || '';
  }

  ngOnInit(): void {
  }

}
