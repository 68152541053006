import { Injectable } from '@angular/core';
import { IgoogleAnalyticsEvent } from './google-analytics-event.interface';

// declare let gtag: (t: string, ac: string, event: IgoogleAnalyticsEvent) => void;
declare let gtag: Function;


@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

/**
 * Envía un evento a analitics
 * @param action Nombre de la acción
 * @param event IgoogleAnalyticsEvent con al información del evento
 * @doc https://developers.google.com/analytics/devguides/collection/gtagjs/events
 */
 public eventEmitter(action: string, event: IgoogleAnalyticsEvent): void {
            gtag('event', action, {
                    event_category: event.event_category,
                    event_label: event.event_label,
                    value: event.value
                  });
       }
}
