<ng-container *ngIf="!serverDataError; else serverError">
  <ng-container *ngIf="!errorNoInitData; else noInitData">

    <div class="loading" *ngIf="loading">
      <mat-spinner color="accent" diameter="30"></mat-spinner>
      <div>Loading data...</div>

    </div>

    <ng-container *ngIf="!loading">

      <form [formGroup]="form">

        <mat-form-field>
          <mat-label>Servidor</mat-label>
          <mat-select formControlName="server">
            <mat-option *ngFor="let server of servidores" [value]="server">{{ server.desc | titlecase}}</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="circuitCatSelector">
          <mat-form-field>
            <mat-label>Circuito</mat-label>
            <mat-select formControlName="circuito">
              <mat-option *ngFor="let circuito of circuitos" [value]="circuito">{{ circuito | titlecase}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Categoría</mat-label>
            <mat-select formControlName="categoria">
              <mat-option *ngFor="let cat of categorias" [value]="cat">{{ cat }}</mat-option>
            </mat-select>
          </mat-form-field>

        </div>

      </form>

      <div class="loading" *ngIf="loadingTimetable">
        <mat-spinner color="accent" diameter="30"></mat-spinner>
        <div>Cargando tiempos...</div>
      </div>

    </ng-container>



    <div class="tiempos" *ngIf="serverTimes">

      <mat-radio-group aria-label="Select an option" [(ngModel)]="currentTimeTable" (change)="selectTimeTable()">
        <mat-radio-button value="all">Todos</mat-radio-button>
        <mat-radio-button value="dry">Seco</mat-radio-button>
        <mat-radio-button value="wet">Mojado</mat-radio-button>
      </mat-radio-group>

      <mat-form-field *ngIf="sessionCars?.length">
        <mat-label>Filtro por coche</mat-label>
        <mat-select (selectionChange)="filtrarTiemposPorCoche()" [(ngModel)]="currentFilterCar">
          <mat-option [value]="null">Todos</mat-option>
          <mat-option *ngFor="let coche of sessionCars" [value]="coche">{{ coche | titlecase}}</mat-option>
        </mat-select>
      </mat-form-field>

      <table *ngIf="!noTimes" mat-table [dataSource]="tiempos" class="mat-elevation-z8">
        <!-- <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
          <th mat-header-cell *matHeaderCellDef> {{column | uppercase }} </th>
          <td  mat-cell *matCellDef="let element"> {{element[column] && element[column].toString() | lowercase | titlecase}} </td>
        </ng-container> -->
        <ng-container matColumnDef="pos">
          <th mat-header-cell *matHeaderCellDef>Pos</th>
          <td mat-cell *matCellDef="let element"> {{ element.pos }} </td>
        </ng-container>

        <ng-container matColumnDef="fullName">
          <th mat-header-cell *matHeaderCellDef>Piloto</th>
          <td mat-cell *matCellDef="let element">
            <div class="pilotName">
              {{ element.fullName | lowercase | titlecase }}
            </div>
            <div class="plusInfo">{{ element.carModelDes }}</div>

          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="carModelDes">
          <th mat-header-cell *matHeaderCellDef>Coche</th>
          <td mat-cell *matCellDef="let element"> {{ element.carModelDes }} </td>
        </ng-container> -->

        <ng-container matColumnDef="validLaps">
          <th mat-header-cell *matHeaderCellDef class="center">Vueltas válidas</th>
          <td mat-cell *matCellDef="let element" class="center"> {{ element.validLaps }} </td>
        </ng-container>

        <ng-container matColumnDef="totalLaps">
          <th mat-header-cell *matHeaderCellDef class="center">Vueltas totales</th>
          <td mat-cell *matCellDef="let element" class="center"> {{ element.totalLaps }} </td>
        </ng-container>

        <ng-container matColumnDef="bestLaptimeFmt">
          <th mat-header-cell *matHeaderCellDef class="center">Mejor Tiempo</th>
          <td mat-cell *matCellDef="let element" class="center">
            {{ element.bestLaptimeFmt }}
            <div  class="plusInfo">{{ element.gapToFirst === 0 ? '' : element.gapToFirstFmt ? '+' + element.gapToFirstFmt: ''}}</div>
          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="gapToFirstFmt">
          <th mat-header-cell *matHeaderCellDef>Gap</th>
          <td mat-cell *matCellDef="let element"> {{ element.gapToFirst === 0 ? '' : element.gapToFirstFmt? '+' + element.gapToFirstFmt: ''}} </td>
        </ng-container> -->

        <ng-container matColumnDef="split1">
          <th mat-header-cell *matHeaderCellDef>Split1</th>
          <td mat-cell *matCellDef="let element">
            {{ getSplit(element, 1) }}
            <div  class="plusInfo" [ngClass]="{'splitMejorado': element.split1Gap < 0}">{{ element.split1Gap | number: '1.3' }}</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="split2">
          <th mat-header-cell *matHeaderCellDef>Split2</th>
          <td mat-cell *matCellDef="let element">
            {{ getSplit(element, 2) }}
            <div  class="plusInfo" [ngClass]="{'splitMejorado': element.split2Gap < 0}">{{ element.split2Gap | number: '1.3' }}</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="split3">
          <th mat-header-cell *matHeaderCellDef>Split3</th>
          <td mat-cell *matCellDef="let element">
            {{ getSplit(element, 3) }}
            <div  class="plusInfo" [ngClass]="{'splitMejorado': element.split3Gap < 0}">{{ element.split3Gap | number: '1.3'}}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="isWetTime">
          <th mat-header-cell *matHeaderCellDef class="center"></th>
          <td mat-cell *matCellDef="let element" class="center">
            <mat-icon class="mat-14" *ngIf="element.isWetTime">wb_cloudy</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row [ngClass]="rowClass(row.gapToFirst)" *matRowDef="let row; columns: columnsToDisplay;"></tr>

      </table>


      <div class="leyendas" *ngIf="!noTimes">
        <div class="leyendaTiempo">
          <span [ngClass]="rowClass(0)"></span>
          Primero
        </div>
        <div class="leyendaTiempo">
          <span [ngClass]="rowClass(0.5)"></span>
          Gap < 1 </div>
            <div class="leyendaTiempo">
              <span [ngClass]="rowClass(1.5)"></span>
              Gap < 2 </div>
                <div class="leyendaTiempo">
                  <span [ngClass]="rowClass(2.5)"></span>
                  Gap < 3 </div>
                    <div class="leyendaTiempo">
                      <span [ngClass]="rowClass(3.5)"></span>
                      Gap < 4 </div>
                        <div class="leyendaTiempo">
                          <span [ngClass]="rowClass(4.5)"></span>
                          Gap < 5 </div>
                            <div class="leyendaTiempo">
                              <span [ngClass]="rowClass(5.5)"></span>
                              Gap + 5
                            </div>
                        </div>

                    </div>

                    <div *ngIf="noTimes">
                      <div class="message warn">
                        No se han marcado tiempos
                      </div>

                    </div>

  </ng-container>

</ng-container>



<ng-template #noInitData>
  <div class="message error">
    No hay datos de servidores, circuitos y categorías. La aplicación no puede continuar.
  </div>
</ng-template>

<ng-template #serverError>
  <div class="message error">
    Error de comunicación con el servidor.
  </div>
</ng-template>
